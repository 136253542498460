import React from 'react';
import { PORTAL_URL } from 'src/constants/pathConsts';
import { CopilotBrandingIcon } from 'src/legacy/components/Icons';
import { Body } from 'copilot-design-system';

export const AuthScreenPortalBadge = () => {
  return <AuthScreenPortalBadgeV2 />;
};

export const AuthScreenPortalBadgeV2 = () => {
  return (
    <div className="group py-3 pr-4 z-10 relative rounded mt-5 hover:[&>a>svg]:text-black-heading">
      <a
        href={`${PORTAL_URL}/?utm_medium=powered_by&utm_source=${encodeURIComponent(
          window.location.href,
        )}`}
        target="_blank"
        className="flex items-center gap-2 justify-center text-gray-small group-hover:text-black-heading no-underline"
      >
        <Body className="text-[12px]">Powered by</Body>
        <CopilotBrandingIcon
          style={{ height: '12px', width: '55px' }}
          className="text-gray-small group-hover:text-black-heading"
        />
      </a>
    </div>
  );
};
